import { createTheme } from '@mantine/core';

import classes from './theme.module.css';

export const fontSizes = {
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '18px',
  xl: '20px',
  xxl: '30px',
};

export type PrimaryColors = 'embla' | 'menthol' | 'cream' | 'neutral';

export type Shades =
  | 'neutral.0'
  | 'neutral.1'
  | 'neutral.2'
  | 'neutral.3'
  | 'neutral.4'
  | 'neutral.5'
  | 'neutral.6'
  | 'neutral.7'
  | 'embla.0'
  | 'embla.1'
  | 'embla.2'
  | 'embla.3'
  | 'embla.4'
  | 'embla.5'
  | 'menthol.0'
  | 'menthol.1'
  | 'menthol.2'
  | 'menthol.3'
  | 'menthol.4'
  | 'menthol.5'
  | 'cream.0'
  | 'cream.1'
  | 'cream.2'
  | 'cream.3'
  | 'cream.4'
  | 'cream.5'
  | 'success.0'
  | 'success.1'
  | 'success.2'
  | 'success.3'
  | 'success.4'
  | 'success.5'
  | 'warning.0'
  | 'warning.1'
  | 'warning.2'
  | 'warning.3'
  | 'warning.4'
  | 'warning.5'
  | 'error.0'
  | 'error.1'
  | 'error.2'
  | 'error.3'
  | 'error.4'
  | 'error.5';

export type ExtendedColors = 'success' | 'warning' | 'error' | 'blue';

export type CustomColors = PrimaryColors | ExtendedColors | Shades;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const colors: Record<
  CustomColors,
  [string, string, string, string, string, string, string, string, string, string]
> = {
  neutral: [
    '#FFFFFF',
    '#FBFBFB',
    '#E6E7E6',
    '#D6D7D6',
    '#ADAFAD',
    '#8D908D',
    '#5E605E',
    '#1F201F',
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
  ],
  embla: [
    '#E0ECE8',
    '#A1C5BA',
    '#72A898',
    '#4F7F71',
    '#3B5F55',
    '#283F38',
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
  ],
  menthol: [
    '#E2F2EA',
    '#B8E0CC',
    '#6CBE96',
    '#49A578',
    '#377C5A',
    '#25533C',
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade,
  ],
  cream: [
    '#FAF8F2',
    '#F6F2E4',
    '#F1EBD7',
    '#E0D3A7',
    '#CFBB76',
    '#BEA346',
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade,
  ],
  success: [
    '#ECFDF3',
    '#D1FADF',
    '#6CE9A6',
    '#1EC66C',
    '#169451',
    '#0B4A28',
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
  ],
  warning: [
    '#FFFAEB',
    '#FEF0C7',
    '#FDB022',
    '#DC6803',
    '#B54708',
    '#7A2E0E',
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
  ],
  error: [
    '#FEF3F2',
    '#EDA4A4',
    '#DA4A4A',
    '#C62828',
    '#941E1E',
    '#631414',
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade
    '#EE46BC', // placeholder shade,
  ],
};

export const theme = createTheme({
  components: {
    Progress: {
      defaultProps: {
        style: {
          backgroundColor: colors.menthol[1],
        },
      },
    },
    TextInput: {
      classNames: {
        input: classes.input,
        error: classes.inputError,
      },
      defaultProps: {
        size: 'lg',
        py: 'xs',
        color: 'neutral.5',
      },
    },
    NumberInput: {
      classNames: {
        input: classes.input,
        error: classes.inputError,
      },
      defaultProps: {
        size: 'lg',
        py: 'xs',
      },
    },
    Checkbox: {
      defaultProps: {
        fontSize: 'lg',
        size: 'md',
        color: 'menthol.3',
      },
    },
    Radio: {
      defaultProps: {
        fontSize: 'lg',
        size: 'md',
      },
    },
    Button: {
      classNames: {
        section: classes.buttonSection,
      },
      defaultProps: {
        radius: 'xl',
        size: 'lg',
      },
    },
    Select: {
      classNames: {
        input: classes.input,
        item: classes.selectItem,
      },
    },
    Tooltip: {
      defaultProps: {
        withArrow: true,
        arrowSize: 7,
        py: 'sm',
        px: 'md',
        multiline: true,
        w: 250,
        bg: 'embla.4',
        events: {
          hover: true,
          touch: true,
        },
      },
    },
  },
  colors,
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  primaryColor: 'menthol',
  primaryShade: 3,
  fontFamily: 'ProximaNova',
  fontSizes: fontSizes,

  headings: {
    fontFamily: 'ProximaNova',
    fontWeight: '400',
    sizes: {
      h1: {
        fontSize: '48px', // lg
      },
      h2: {
        fontSize: '36px', // md
      },
      h3: {
        fontSize: '30px', // sm
      },
      h4: {
        fontSize: '24px', // xs
      },
      h5: {
        fontSize: '20px', // xxs
      },
    },
  },
  defaultRadius: 'lg',
});
